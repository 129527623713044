import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useState } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

type Option = {
  id: string;
  name: string;
  image: string;
};

type Options = any[];

type Props = {
  options: Options;
  doSomethingWithSelectedItem?: (selected: Option) => void;
  position?: "top" | "bottom";
  toggleBtnBorder?: boolean;
  styleContainer?: string;
  styleBtn?: string;
  showArrow?: boolean;
};

export default function SelectWithImage({
  options,
  doSomethingWithSelectedItem,
  position = "bottom",
  toggleBtnBorder,
  styleContainer,
  styleBtn,
  showArrow = true,
}: Props) {
  const [selected, setSelected] = useState(options[0]);

  const handleSelect = (selected: Option) => {
    setSelected(selected);
    doSomethingWithSelectedItem && doSomethingWithSelectedItem(selected);
  };

  return (
    <Listbox value={selected} onChange={handleSelect}>
      {({ open }) => (
        <div className="relative">
          <Listbox.Button
            className={`${
              toggleBtnBorder && "border border-dark-pri "
            } text-white relative w-full hover:bg-primary transition cursor-pointer rounded-lg py-3 pl-3 pr-10 text-left  shadow-sm  sm:text-sm sm:leading-6 ${styleBtn}`}
          >
            <span className="flex items-center w-full justify-center">
              <img
                src={selected.image}
                alt=""
                className="h-5 w-5 min-w-5 object-contain rounded-full"
              />
              {/* {selected.label && (
                <span className="ml-3 block truncate">{selected.label}</span>
              )} */}
            </span>
            {showArrow && (
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon className="h-5 w-5 " aria-hidden="true" />
              </span>
            )}
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`absolute z-10 ${
                position === "top" ? "bottom-full" : "top-full mt-2"
              } ${
                styleContainer && styleContainer
              } mb-1  min-w-full w-auto overflow-auto rounded-md bg-dark-default border border-dark-default-1 py-1 
              text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm scrollbar-hide`}
            >
              {options.map((option) => (
                <Listbox.Option
                  key={option.id}
                  className={({ active }) =>
                    classNames(
                      active ? "bg-primary text-white" : "text-light-gray",
                      "relative transition cursor-default select-none py-2 pl-3 pr-9 !min-w-[180px]"
                    )
                  }
                  value={option}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="w-auto flex items-center">
                        <img
                          src={option.image}
                          alt=""
                          className="h-5 w-5 flex-shrink-0 rounded-full"
                        />
                        {option.label && (
                          <span
                            className={classNames(
                              selected ? "font-semibold" : "font-normal",
                              "ml-3  whitespace-nowrap"
                            )}
                          >
                            {option.label}
                          </span>
                        )}
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-dark-pri",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
