"use client";
import SmolPrimaryBtn from "../Forms/Button/SmolPrimaryBtn";
import SectionHeading from "./SectionHeading";

type Props = {
  search: (querySearch: any) => Promise<void>;
};

export default function PopularSearches({ search }: Props) {
  const POPULAR_SEARCHES = [
    "Bored Ape Yacht Club",
    "My Pet Hooligan",
    "Otherdeed for Otherside",
    "Parallel Planetfall",
    "Pudgy Penguins",
    "MoonCats",
    "DeGods",
  ];
  return (
    <div className="grid  pb-2 gap-4">
      <SectionHeading title="Popular Searches" icon="❤️" />
      <div className="flex flex-wrap gap-x-4 pt-4 gap-y-4">
        {POPULAR_SEARCHES.map((query) => (
          <SmolPrimaryBtn
            key={query}
            onClick={() => search(query)}
            ariaLabel={`Search for ${query}`}
          >
            {query}
          </SmolPrimaryBtn>
        ))}
      </div>
    </div>
  );
}
