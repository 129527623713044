import Tooltip from "@mui/material/Tooltip";

type Props = {
  showTooltip?: boolean;
};

export default function IconVerifiedCheck({ showTooltip = true }: Props) {
  if (showTooltip === true) {
    return (
      <Tooltip placement="top" title="Verified" arrow>
        <div className="bg-primary relative neonLite rounded-full w-[24px] h-[24px] opacity-90">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="3.5"
            stroke="#74fb7b"
            className="w-6 h-6 absolute -mt-[2px] ml-[3px] rotate-[8deg]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        </div>
      </Tooltip>
    );
  } else {
    return (
      <div className="bg-primary relative neonLite rounded-full w-[24px] h-[24px] opacity-90">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="3.5"
          stroke="#74fb7b"
          className="w-6 h-6 absolute -mt-[2px] ml-[3px] rotate-[8deg]"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m4.5 12.75 6 6 9-13.5"
          />
        </svg>
      </div>
    );
  }
}
