"use client";
import { AnimatePresence, motion } from "framer-motion";
import { jokes } from "./data";
import { useEffect, useState } from "react";
import IconRefresh from "../../Icons/IconRefresh";
import SmolPrimaryBtn from "../../Forms/Button/SmolPrimaryBtn";

export default function Jokes() {
  const [tellAJoke, setTellAJoke] = useState(false);
  const [randNum, setRandNum] = useState(0);
  const [counter, setCounter] = useState(0);

  const getRandNumber = () => {
    return Math.floor(Math.random() * jokes.length);
  };

  const getJoke = () => {
    // Rick roll after 3 jokes
    if (counter > 2) {
      setCounter(0);
      window.open(
        "https://www.yout-ube.com/watch?v=dQw4w9WgXcQ",
        "_blank",
        "noopener noreferrer"
      );
      return;
    }
    const randomNumber = getRandNumber();
    setRandNum(randomNumber);
    setCounter(counter + 1);
  };

  useEffect(() => {
    const randomNumber = getRandNumber();
    setRandNum(randomNumber);
  }, []);

  return (
    <div className="pt-2 ml-2 relative min-h-[100px]">
      <AnimatePresence>
        {!tellAJoke ? (
          <SmolPrimaryBtn onClick={() => setTellAJoke(true)}>
            Tell me a joke
          </SmolPrimaryBtn>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 200 }}
            key="joke"
            animate={{
              opacity: 1,
              y: 1,
              transition: { duration: 0.5 },
            }}
            exit={{ opacity: 0, y: 200 }}
            className="absolute top-2 grid max-w-[300px] items-center justify-center 
          gap-y-[24px] text-center  font-400 text-light-gray xl:max-w-[340px]"
          >
            {jokes[randNum]}
            <button
              onClick={() => getJoke()}
              className="mx-auto hover:text-dark-pri"
            >
              <IconRefresh />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
