const jokes = [
  "Why don't eggs tell jokes? Because they might crack up!",
  'What time did the man go to the dentist? Tooth-hurty.',
  'Did you hear about the circus fire? It was in tents.',
  "Why don't scientists trust atoms? Because they make up everything!",
  'How do you make a tissue dance? Put a little boogey in it!',
  "Why couldn't the bicycle stand up by itself? It was two-tired.",
  'What do you call an alligator in a vest? An investigator!',
  "What's orange and sounds like a parrot? A carrot.",
  'What do you get when you cross a snowman and a vampire? Frostbite.',
  'What did the janitor say when he jumped out of the closet? “Supplies!”',
  'Why did the scarecrow win an award? Because he was outstanding in his field!',
  "What do you call cheese that isn't yours? Nacho cheese.",
  'How does a penguin build its house? Igloos it together.',
  'Why can’t you give Elsa a balloon? Because she will let it go.',
  'What do you call a fake noodle? An impasta.',
  'Why was the math book sad? Because it had too many problems.',
  'What do you call a belt made out of watches? A waist of time.',
  'What do you call a man with a rubber toe? Roberto.',
  'How do you catch a squirrel? Climb a tree and act like a nut!',
  'What’s brown and sticky? A stick.',
  "Why don't skeletons fight each other? They don't have the guts.",
  'What do you call a boomerang that won’t come back? A stick.',
  'Why did the coffee file a police report? It got mugged.',
  'How do you make holy water? You boil the hell out of it.',
  "What's a ghost's favorite dessert? Boo-berries!",
  'Why did the bicycle fall over? It was two tired.',
  'How do you organize a space party? You planet.',
  'Why did the golfer bring two pairs of pants? In case he got a hole in one.',
  'What do you call a bear with no teeth? A gummy bear.',
  'Why can\'t you hear a pterodactyl go to the bathroom? Because the "P" is silent.',
  'What did the fish say when it hit the wall? Dam.',
  'What’s the best thing about Switzerland? I don’t know, but the flag is a big plus.',
  'What do you get from a pampered cow? Spoiled milk.',
  'Did you hear about the restaurant on the moon? Great food, no atmosphere.',
  'Why did the tomato turn red? Because it saw the salad dressing!',
  'What do you call a pile of cats? A meowtain.',
  "What's the best way to watch a fly fishing tournament? Live stream.",
  'What do you call a factory that makes okay products? A satisfactory.',
  'What did one wall say to the other wall? "I\'ll meet you at the corner!"',
  'Why don’t skeletons fight each other? They don’t have the guts.',
  'What did the grape do when he got stepped on? He let out a little wine!',
  'What do you call a man who can’t stand? Neil.',
  "Why can't a nose be 12 inches long? Because then it would be a foot.",
  'What do you call an elephant that doesn’t matter? An irrelephant.',
  "Why couldn't the leopard play hide and seek? Because he was always spotted.",
  'What do you call a can opener that doesn’t work? A can’t opener.'
];

export { jokes };
