import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useRouter } from "next/navigation";
import { SetStateAction, useState } from "react";
import SmolPrimaryBtn from "../Forms/Button/SmolPrimaryBtn";
import { isEVMAddress } from "@/utils/network";
import { useSearchModalStore } from ".";

type Props = {
  collectionAddress: string;
  network: string;
  setErrorMessage: (value: SetStateAction<string>) => void;
  showErrorMessage: () => void;
  showError: boolean;
  errorMessage: string;
};

export default function GoDirectToToken({
  collectionAddress,
  network,
  setErrorMessage,
  showErrorMessage,
  showError,
  errorMessage,
}: Props) {
  const [tokenId, setTokenId] = useState("");
  const { setSearchModal } = useSearchModalStore();

  const router = useRouter();

  const handleGoToToken = () => {
    if (collectionAddress.length === 0) {
      setErrorMessage("Collection address is required");
      showErrorMessage();
      return;
    }
    if (tokenId.length === 0) {
      setErrorMessage("Token ID address is required");
      showErrorMessage();
      return;
    }
    if (isEVMAddress(collectionAddress) === false) {
      setErrorMessage("Please provide a valid collection address.");
      showErrorMessage();
      return;
    }
    // Go to token
    router.push(
      `/nft/${network?.toLowerCase()}/${collectionAddress}/${tokenId}`
    );
    // close search modal
    setSearchModal(false);
  };

  return (
    <>
      <div className="relative w-full lg:w-[400px]">
        <label htmlFor="token-id" className="sr-only">
          Token Id
        </label>
        <input
          id="token-id"
          name="token-id"
          value={tokenId}
          onChange={(e) => setTokenId(e.target.value)}
          className="w-full min-w-0 outline-none flex-auto rounded-md border-2 border-white/20 
    bg-white/5 px-3.5 py-2 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri
     sm:leading-6"
          placeholder="Token Id..."
        />
        <div className="absolute right-1 top-1/2 -translate-y-1/2">
          <SmolPrimaryBtn onClick={handleGoToToken}>Go to NFT</SmolPrimaryBtn>
        </div>
      </div>
      <Snackbar
        open={showError}
        message={errorMessage}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#7654FF",
          },
        }}
      />
    </>
  );
}
