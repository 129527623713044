"use client";
import {
  ARBITRUM_MAINNET,
  BASE_MAINNET,
  ETHEREUM_MAINNET,
  OPTIMISM_MAINNET,
  POLYGON_MAINNET,
  STARKNET_MAINNET,
} from "@/constants/chains";
import Snackbar from "@mui/material/Snackbar/Snackbar";
import { useRouter } from "next/navigation";
import { useState } from "react";
import SmolPrimaryBtn from "../Forms/Button/SmolPrimaryBtn";
import SelectWithImage from "../Forms/Select/SelectWithImage";
import GoDirectToToken from "./GoDirectToToken";
import SectionHeading from "./SectionHeading";
import { isEVMAddress } from "@/utils/network";
import { useSearchModalStore } from ".";

export default function GoDirectToCollection() {
  const [collectionAddress, setCollectionAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [network, setNetwork] = useState(ETHEREUM_MAINNET.name); // Default network is ethereum
  const [showError, setShowError] = useState(false);
  const { setSearchModal } = useSearchModalStore();

  const showErrorMessage = () => {
    setShowError(true);
    setTimeout(() => {
      setShowError(false);
    }, 3000);
  };

  const router = useRouter();

  const handleGoToCollection = () => {
    if (collectionAddress.length === 0) {
      setErrorMessage("Collection address is required");
      showErrorMessage();
      return;
    }
    if (isEVMAddress(collectionAddress) === false) {
      setErrorMessage("Please provide a valid collection address.");
      showErrorMessage();
      return;
    }
    // Go to collection
    router.push(`/collections/${network?.toLowerCase()}/${collectionAddress}`);
    // close search modal
    setSearchModal(false);
  };

  const networkOptions = [
    {
      label: "Ethereum",
      value: ETHEREUM_MAINNET.chainId,
      name: ETHEREUM_MAINNET.name,
      image: `/network/${ETHEREUM_MAINNET.chainId}.png`,
    },
    {
      label: "Polygon",
      value: POLYGON_MAINNET.chainId,
      name: POLYGON_MAINNET.name,
      image: `/network/${POLYGON_MAINNET.chainId}.png`,
    },
    {
      label: "Arbitrum",
      value: ARBITRUM_MAINNET.chainId,
      name: ARBITRUM_MAINNET.name,
      image: `/network/${ARBITRUM_MAINNET.chainId}.png`,
    },
    {
      label: "Optimism",
      value: OPTIMISM_MAINNET.chainId,
      name: OPTIMISM_MAINNET.name,
      image: `/network/${OPTIMISM_MAINNET.chainId}.png`,
    },
    {
      label: "Base",
      value: BASE_MAINNET.chainId,
      name: BASE_MAINNET.name,
      image: `/network/${BASE_MAINNET.chainId}.png`,
    },
    {
      label: "Starknet",
      value: STARKNET_MAINNET.chainId,
      name: STARKNET_MAINNET.name,
      image: `/network/${STARKNET_MAINNET.name}.png`,
    },
  ];

  const handleNetworkChange = (selectedItem) => {
    if (selectedItem) {
      setNetwork(selectedItem.name);
    }
  };
  return (
    <div className=" pt-4 sm:pt-10 flex flex-col gap-4">
      <div>
        <div className="relative">
          <div className="rounded-full -z-10 absolute left-[45%] top-1/2 blur-[32px] bg-dark-pri w-[70px] h-[40px]" />
          <img
            src="/cowboy-hat.png"
            className="flex justify-center mx-auto my-2 object-contain w-[120px]"
          />
        </div>
        <div className="flex items-center justify-center text-center ">
          <SectionHeading title="Not your first rodeyo?" />
        </div>
      </div>
      <div className="mt-4 grid gap-4 sm:flex gap-x-3 w-full items-center">
        <div className="relative w-full">
          <label htmlFor="collection-address" className="sr-only">
            Collection address
          </label>
          <input
            id="collection-address"
            name="collection-address"
            value={collectionAddress}
            onChange={(e) => setCollectionAddress(e.target.value)}
            className="w-full min-w-0 pl-[90px] sm:pl-[100px] outline-none flex-auto rounded-md border-2 border-white/20 
            bg-white/5 pr-3.5 py-2 text-white shadow-sm  hover:border-dark-pri focus:border-dark-pri
             sm:leading-6"
            placeholder="Collection address..."
          />
          {/* Select Blockchain */}
          <div className="mb-2 w-auto sm:min-w-[40px] absolute left-1 top-1/2 -translate-y-1/2 cursor-pointer">
            <SelectWithImage
              options={networkOptions}
              doSomethingWithSelectedItem={handleNetworkChange}
              position="top"
              styleBtn="!bg-primary px-2 !py-2 "
            />
          </div>
          <div className="absolute right-1 top-1/2 -translate-y-1/2 hidden sm:flex">
            <SmolPrimaryBtn onClick={handleGoToCollection}>
              Go to Collection
            </SmolPrimaryBtn>
          </div>
          <div className="absolute right-1 top-1/2 -translate-y-1/2  sm:hidden">
            <SmolPrimaryBtn onClick={handleGoToCollection}>Go</SmolPrimaryBtn>
          </div>
        </div>
        {/* Token Id */}
        <GoDirectToToken
          collectionAddress={collectionAddress}
          network={network}
          setErrorMessage={setErrorMessage}
          showErrorMessage={showErrorMessage}
          showError={showError}
          errorMessage={errorMessage}
        />
      </div>

      <Snackbar
        open={showError}
        message={errorMessage}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#7654FF",
          },
        }}
      />
    </div>
  );
}
