import { useRouter } from "next/navigation";
import { Dispatch, SetStateAction, useState } from "react";
import SectionHeading from "./SectionHeading";
import ContactUs from "../ContactForm";
import Modal from "../Modal";
import Alert from "../Alert";

type Props = {
  setSearchModal: Dispatch<SetStateAction<boolean>>;
};

export default function Contact({ setSearchModal }: Props) {
  const router = useRouter();
  const [contactModal, setContactModal] = useState(false);
  const handleClickMessage = () => {
    setContactModal(true);
  };

  const handleClickPartner = () => {
    router.push("/launchpad");
    setSearchModal(false);
  };
  const CONTACT_METHODS = [
    {
      name: "Message us",
      onClick: handleClickMessage,
    },

    // {
    //   name: "Launchpad",
    //   onClick: handleClickPartner,
    // },
  ];

  const [snackbar, setSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [alertType, setAlertType] = useState("default");
  return (
    <>
      <div className="grid mt-6 sm:mt-0 gap-4">
        <div className="pb-2 ">
          <SectionHeading title="Let's talk" icon="💬" />
          <div className="grid gap-x-4 gap-y-4 pt-4">
            {CONTACT_METHODS.map((item) => (
              <button
                key={item.name}
                onClick={item.onClick}
                className="opacity-90 text-left hover:opacity-100 transition hover:scale-105"
              >
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
      <Modal open={contactModal} setOpen={setContactModal} width="sm:w-[80%]">
        <ContactUs
          setContactModal={setContactModal}
          setSnackbar={setSnackbar}
          setSnackbarMessage={setSnackbarMessage}
          setAlertType={setAlertType}
        />
      </Modal>
      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
        type={alertType}
      />
    </>
  );
}
